export const ky = {
  "n1": "алдыңкы бет",
  "n2": "Буйрук",
  "n3": "соода",
  "n4": "команда",
  "n5": "меники",
  "t1": "Туура эмес",
  "t2": "Репликация",
  "t3": "Сураныч, маалыматты толтуруңуз",
  "t4": "жүктөө ...",
  "t5": "Ийгиликтүү конууга",
  "t6": "Упай 3төн чоңураак болушу керек",
  "t7": "Ийгиликтүү тапшыруу",
  "t8": "Жетишсиз баланс, ала албай",
  "t9": "Тапшыруу",
  "t10": "Сураныч, алгачкы даректи байлап коюңуз жана жеке борбордун баракчасы секирип жатат",
  "t11": "Милдеттүү дареги үчүн, өзгөртүү үчүн кардарларды тейлөө кызматына кайрылыңыз",
  "t12": "Өзгөртүү",
  "t13": "Өзгөртүү",
  "t14": "Сырсөздү өзгөртүү үчүн кардарларды тейлөө бөлүмүнө кайрылыңыз",
  "t15": "Сырсөздү формат",
  "t16": "Ийгиликтүү чыгуу",
  "t17": "Көпчүлүк чыгарып алуу",
  "t18": "Минималдуу акча",
  "t19": "Эки сырсөз туура келбейт",
  "t20": "кыйытма",
  "t21": "Убактылуу секире албай жатат",
  "t22": "Сырсөздү формат",
  "t23": "Ийгиликтүү каттоо",
  "t24": "Сураныч, аймак кодун киргизиңиз",
  "t25": "Жетишет",
  "l1": "тил",
  "l2": "Сураныч, эсептин номерин киргизиңиз",
  "l3": "Сырсөздү киргизиңиз",
  "l4": "эстөө",
  "l5": "Кирүү",
  "l6": "Катталуу",
  "l7": "Эсеп номери жок",
  "l8": "Телефон номери",
  "l9": "Ник аты",
  "l10": "купуя сөз",
  "l11": "Сырсөздү ыраста",
  "l12": "Чакыруу коду",
  "l13": "Катталуу",
  "l14": "Учурдагы эсеп",
  "l15": "Кирүү",
  "l16": "Сураныч, 6-16 орундуу санарип сырсөзүн киргизиңиз",
  "bz1": "жардам",
  "bz2": "Жалпы көйгөй",
  "bz3": "Соода эрежелери",
  "bz4": "Акчаны кантип алуу керек",
  "bz5": "Кантип толтуруу керек",
  "bz6": "1. Робот деген эмне?",
  "bz7": "EBay Review робот роботун сереп берүүчү роботторду онлайн режиминде сатууга жардам берген маркетингдик компания, бул бүт буйрутма менен көбүрөөк буйрутма алуу жана EBay серептеринен EBay серептеринен EBay серептеринен алынган маалыматтарды көбөйтүүгө жардам берет. Биз eBay сереп роботунун жана керектөөчүлөрдүн ортосунда үч-брафик киреше маркетинг моделин түзүүгө милдеттүүбүз. Акыркы P2P блоккоин технологиясын бириктирип, керектөөчүлөрдү жана соодагерлерди АКШ долларына чейин туташтырыңыз (TRC20, ERC20) аркылуу туташтырыңыз. Катталган колдонуучулар буйрутмаларды тапшыра алышат, ал эми соодагерлер дүкөндүн сатуу маалыматтарын көбөйтө алышат. Бул Интернеттеги блоккоин моделиндеги акыркы киреше модели!",
  "bz8": "2. Мурунку иш-аракет кандайча робот?",
  "bz9": "EBay сереп роботунун күнүмдүк кайтарылышы, EBay серептеринде өркүндөтүлүшү керек болгон продукциялар робот сатуу, колдонуучулар тутумдагы буйрутма жазылууларды автоматтык түрдө түзүү үчүн бир гана нерсени оңой эле чыкылдатыңыз. Колдонуучулар буйрутманын суммасын блончейндин суммасы аркылуу төлөп, күн сайын комиссияларды алышат.",
  "bz10": "3. Эмне үчүн бир эле валютанын баасы эки бүтүмдө айырмачылык бар?",
  "bz11": "Баанын айырмачылыктары валюталардын эркиндигине, тармак шарттары, валюталардын шарттары, ар кайсы аймактардагы адамдар, ал тургай, алмашуу, ал тургай, соода түрлөрү жана транзактивдүү търлёрдёрдън, ал тургай, валюталардын шарттары, валюталардын шарттары, валюталардын шарттары, ал тургай, соода търлёрлары Демек, ошол эле валюта ар кандай бүтүмдөрдөгү баа айырмачылыктарга алып келиши мүмкүн.",
  "bz12": "4. Инвестициялык пайда?",
  "bz13": "Сиз алган бааңыз канчалык жогору болсо, сиз ала турган киреше ошончолук жогору болот. Ошол эле учурда, eBay баяндамасы робот кокусунан ири комиссиянын буйруктарын бөлүп берет.",
  "bz14": "Күн сайын тутум автоматтык түрдө пайдалануучуга автоматтык түрдө чыгарат жана бөлүштүрөт. Колдонуучулар 0,6% комиссияны алуу үчүн ар бир буйруктун төлөнгөндүгүн аягына чыгара алышат. Чоң комиссиянын буйруктары туш келди бөлүштүрүлөт.",
  "bz15": "60 буйруктан кийин сумма алынышы мүмкүн. (Эгерде 60 буйрук бүтпөсө, тутум автоматтык түрдө күндүн тартиби суммасына чейин автоматтык түрдө токтотулат)",
  "bz16": "Ар бир эсеп USHTT эсеп эсебине гана байланышса болот. Эгер ката кетсе, кардарларды тейлөө бөлүмүнө кайрылыңыз",
  "bz17": "Күнүмдүк буйрутманы жеткирүүнү аяктагандан кийин, сиз кадимкидей алып кетсеңиз болот. Чыгып кетүүнү баштагандан кийин, сиз 24 сааттын ичинде эсепти ала аласыз, ал эми кантип чектөө жок",
  "bz18": "RECHARGE, RECTEWARE дарегин (ТРК-20) АКШ долларын (ТРК-20) дарегин байлап коюңуз. Колдонмонун же веб-баракчасында заряддоосун чыкылдатып, кайра заряддоо үчүн колдонсоңуз болот (TRC-20)",
  "bz19": "Өз ара толтуруу же банк трансферлери сыяктуу жүрүм-турум жана банктык эсептер менен алмашуу каражаттары бар",
  "ty1": "Албетте",
  "ty2": "Жокко чыгаруу",
  "ty3": "тапшыруу",
  "ty4": "дареги",
  "s1": "Кумулятивдик киреше",
  "s2": "Кызмат кылуу",
  "s3": "биз жөнүндө",
  "s4": "жардам",
  "s5": "Өнөктөш",
  "s6": "Менин кызматым",
  "d1": "Бүтпөгөн",
  "d2": "Толук",
  "d3": "Маалымат жок",
  "d4": "Бир сан",
  "d5": "Транзакция убактысы",
  "d6": "Суммасы",
  "d7": "Учурдагы тапшырма номери",
  "d8": "Күтүлүүчү киреше",
  "d9": "Дагы эле",
  "d10": "упай",
  "j1": "Эсеп балансы",
  "j2": "Транзакция киришүү",
  "j3": "EBay Review Робот ebay серептерин EBay сертификатын өркүндөтүүгө керектүү продукцияны көрсөтүү үчүн күн сайын кайтарып берүү роботун көрсөтүү үчүн күн сайын жооп кайтарат. Эгерде колдонуучу буйрутманы чыкылдатып турушу керек болсо, тутум автоматтык түрдө буйрутма колун түзө алат. Колдонуучу буйрутманын суммасын АКШ долларынын суммасына төлөйт жана ар бир жолу комиссиянын 0,6% дан ашыгын алат, ал эми тутум чоң сыйлык буйруктарын туш келди бөлүштүрө алат.",
  "j4": "Дал келүүнү баштоо",
  "j5": "Бүгүнкү пайдасы",
  "j6": "Аяктаган номер",
  "j7": "Жалпы тапшырма",
  "td1": "Баланс",
  "td2": "комиссия",
  "td3": "алуу",
  "td4": "Жалпы саны",
  "td5": "колдонуучу",
  "td6": "салым кош",
  "td7": "саны",
  "td8": "мамлекет",
  "g1": "Алуу",
  "g2": "Топ",
  "g3": "Досторду чакыруу",
  "g4": "Азыр чакыруу",
  "g5": "Достор тапкан ар бир киреше үчүн, сиз тийиштүү пропорционалдык комиссияны аласыз",
  "g6": "Жеке маалымат",
  "g7": "Капиталдык маалыматтар",
  "g8": "Рекорд жазуу",
  "g9": "Алып салуу Рекорд",
  "g10": "тил",
  "g11": "Чыгуу Кирүү",
  "g12": "Сизден чыгып, кирип жатасызбы?",
  "yh1": "Колдонуучунун маалыматы",
  "yh2": "Кайра карап чыгуу",
  "yh3": "жайгашуу",
  "yh4": "Телефон",
  "yh6": "Оригинал сырсөз",
  "yh7": "жаңы сыр сөз",
  "mx1": "Комиссияны жыйноо",
  "mx2": "киреше",
  "mx3": "Алуу",
  "mx4": "убакыт",
  "mx5": "Толтуруу",
  "mx6": "Баш тартуу",
  "mx7": "Мамиле жасоо",
  "mx8": "Чыгып кетүү дареги",
  "mx9": "Колдо болгон баланс",
  "mx10": "Чындыгында",
  "mx11": "Чыгып кетүү суммасын киргизиңиз",
  "cz2": "көчүрүү",
  "cz3": "Юридикалык валютаны тандоо",
  "cz4": "Төлөө",
  "cz5": "Депозиттик дарек ТРК20-АКШ долларын түзөт, ал эми депозиттин минималдуу суммасы 20USTT",
  "cz6": "Аманаттын дареги ERC20 колдойт, депозиттин минималдуу суммасы 20USTT",
  "cz1": "Аманаттын дареги ERC20 колдойт, депозиттин минималдуу суммасы 20USTT",
  "cz10": "Депозиттик дарек BTCди гана колдойт",
  "qy1": "Аймак коду",
  "yq1": "Досторду чакыруу",
  "yq2": "Чакыруу коду",
  "yq3": "Досторуңузду чоң киреше алуу үчүн чакырыңыз",
  "yq4": "Болсоңчу",
  "new1": "Төлөө ыкмасы"
}
