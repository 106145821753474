export const uz = {
    "n1": "Old sahifa",
    "n2": "Tartib",
    "n3": "savdo",
    "n4": "jamoa",
    "n5": "meniki",
    "t1": "Yanglishmoq",
    "t2": "Ko'paytirish",
    "t3": "Iltimos, ma'lumotni to'ldiring",
    "t4": "yuklash ...",
    "t5": "Muvaffaqiyatli qo'nish",
    "t6": "Hisobni yuborish uchun 3 dan yuqori bo'lishi kerak",
    "t7": "Muvaffaqiyatli taqdim etish",
    "t8": "Muvozanatning etishmasligi, qabul qila olmaydi",
    "t9": "Muvaffaqiyatsiz qilib yuboring",
    "t10": "Iltimos, avval manzilni bog'lab qo'ying va shaxsiy markazning sahifasi sakrash arafasida",
    "t11": "Belgilash manzili uchun iltimos, o'zgartirish uchun mijozlarga xizmat ko'rsatish xizmatiga murojaat qiling",
    "t12": "O'zgartirmoq",
    "t13": "O'zgarmaslik",
    "t14": "Parolni o'zgartirish uchun iltimos, mijozlarga xizmat ko'rsatish xizmatiga murojaat qiling",
    "t15": "Parol formatidagi xato",
    "t16": "Muvaffaqiyatli olib ketish",
    "t17": "Qabul qilishning ko'pi",
    "t18": "Minimal naqd pul",
    "t19": "Ikkita parollar nomuvofiqdir",
    "t20": "ishora qilmoq",
    "t21": "Vaqtincha sakrash mumkin emas",
    "t22": "Parol formatidagi xato",
    "t23": "Muvaffaqiyatli ro'yxatdan o'ting",
    "t24": "Iltimos, mintaqa kodini kiriting",
    "t25": "Boshqa emas; boshqa ... bo'lmaydi; Endi yo'q",
    "l1": "til",
    "l2": "Hisob raqamini kiriting",
    "l3": "Parolni kiriting",
    "l4": "esda tutmoq",
    "l5": "Tizimga kirish",
    "l6": "Ro'yxatga olmoq",
    "l7": "Hisob raqami yo'q",
    "l8": "Telefon raqami",
    "l9": "Nik nomi",
    "l10": "parol",
    "l11": "Parolni tasdiqlang",
    "l12": "Taklifnoma kodi",
    "l13": "ro'yxatga olmoq",
    "l14": "Mavjud hisob",
    "l15": "Tizimga kirish",
    "l16": "Iltimos 6-16 raqamli raqamli parolni kiriting",
    "bz1": "yordam bermoq",
    "bz2": "Keng tarqalgan muammo",
    "bz3": "Savdo qoidalari",
    "bz4": "Qanday qilib pulni olish kerak",
    "bz5": "Qanday zaryadlash kerak",
    "bz6": "1. Thebay robot nima?",
    "bz7": "EBay Tekshiruv roboti - bu yana buyurtmalarni olish uchun EBay Revict-ni butun dunyodagi savdo-sotiqni dunyodagi savdo do'konlariga olib borishga yordam beradigan marketing kompaniyasi. Biz emirlik va savdogarlar va savdogarlar va xaridorlar o'rtasida uchta uchta daromad marketing modelini yaratishga sodiqmiz. P2P blackchin texnologiyasini birlashtirish, iste'molchilarni va savdogarlarni USDT orqali tezda ulang (TRC20, ERC20). Ro'yxatdan o'tgan foydalanuvchilar buyurtma komissiyasini olishlari mumkin, savdogarlar do'konning savdo ma'lumotlarini ko'paytirishi mumkin. Bu Internet Klokchine modelidagi eng so'nggi foyda modeli!",
    "bz8": "2. Qanday qilib forvata robot robot?",
    "bz9": "EBay-ning kunlik sharhi robotlari, eBay tekshiruvi bo'yicha mahsulotlar, eBay tekshiruvi robot sotuvida yaxshilanishi kerak, foydalanuvchilar tizimdagi buyurtma obunalarini avtomatik ravishda yaratish uchun foydalanuvchilar uchun osongina osonlikcha bosing. Foydalanuvchilar buyurtma miqdorini blockchin usdt orqali to'laydilar va kunlik komissiyalar olishadi.",
    "bz10": "3. Ikki bitimda nima uchun valyutaning narxi qanday farq bor?",
    "bz11": "Narx farqlari valyuta uzatish, tarmoq sharoitlari, turli mintaqalardagi valyutalarni cheklash, turli mintaqalardagi odamlar va hatto savdo turlari va bitimlar bilan ta'minlangan barcha omillardan kelib chiqadi. Shuning uchun bir xil valyutaning turli xil operatsiyalarda narx farqlariga olib kelishi mumkin.",
    "bz12": "4. Investitsiya foydasi?",
    "bz13": "Siz olgan narx qanchalik yuqori bo'lsa, sizga foyda keltiradi. Shu bilan birga, eBay tasodifiy Komissiya buyurtmalarini tasodifiy ravishda ajratib turadi.",
    "bz14": "Har kuni tizim foydalanuvchiga 60 ta tovar buyurtmalarini avtomatik ravishda ishlab chiqaradi va tarqatadi. Foydalanuvchilar har bir buyurtmadan 0,6% komissiya orqali to'lashi mumkin. Katta komissiya buyurtmalari tasodifiy taqsimlanadi.",
    "bz15": "USDT 60 buyruqidan keyin olib tashlanishi mumkin. (Agar 60 buyruq tugamagan bo'lsa, tizim profitsitning ortiqcha miqdori bo'yicha avtomatik ravishda to'xtatiladi)",
    "bz16": "Har bir qayd yozuvi faqat USDT hisob raqamiga bog'lanishi mumkin. Agar xato yuz bersa, iltimos, mijozlarga xizmat ko'rsatish bilan bog'laning",
    "bz17": "Kundalik tartibni etkazib berishni tugatgandan so'ng, siz odatdagidan keyin qaytarib olishingiz mumkin. Olib borishni boshlaganingizdan so'ng, siz 24 soat ichida hisobni olasiz va olib qo'yish uchun cheklov bo'lmaydi.",
    "bz18": "Qayta zaryadlashdan oldin, iltimos, ulangan manzilingizni (SRC-20) manzilingizni bosing. Siz qayta zaryadlash uchun foydalanadigan blokirovka yoki veb-sayt (TRC-20) ni tanlang.",
    "bz19": "O'zaro zaryadlash yoki bank o'tkazmalari kabi taqiqlangan xatti-harakatlar va tegishli hisoblar orasidagi pul o'tkazmalari. Xulq-atvorni ayblashda gumon qilingan va xatti-harakatlar hisob raqami bilan jazolanadi",
    "ty1": "Ishonch hosil qilmoq",
    "ty2": "Bekor qilmoq",
    "ty3": "topshirmoq",
    "ty4": "manzil",
    "s1": "To'plangan daromad",
    "s2": "Xizmat qilmoq",
    "s3": "Biz haqimizda",
    "s4": "yordam bermoq",
    "s5": "Sherik",
    "s6": "Mening xizmatim",
    "d1": "Tugallanmagan",
    "d2": "To'liq",
    "d3": "Ma'lumot yo'q",
    "d4": "Bitta raqam",
    "d5": "Tranzaktsion vaqt",
    "d6": "Miqdori",
    "d7": "Joriy vazifa raqami",
    "d8": "Kutilayotgan daromad",
    "d9": "Hanuzgacha",
    "d10": "Xol",
    "j1": "Hisob qoldig'i",
    "j2": "Tranzaktsiyalarni kiritish",
    "j3": "EBay Tekshiruv roboti har kuni eBay tomonidan takomillashtirilishi kerak bo'lgan mahsulotlarni EBAY-ning robot sotish orqali yaxshilanishi kerak bo'lgan mahsulotlarni namoyish qilish uchun foydalanadi. Agar foydalanuvchi faqat buyurtmani bosish kerak bo'lsa, tizim buyurtma imzosini keltirib chiqaradi. Foydalanuvchi AQShning Blokchin orqali buyurtma miqdorini to'laydi va har safar komissiyaning 0,6% dan oshishi mumkin va tizim juda katta mukofot buyurtmalarini tarqatadi.",
    "j4": "Mos kelishni boshlang",
    "j5": "Bugungi foyda",
    "j6": "Tugallangan raqam",
    "j7": "Jami vazifa",
    "td1": "Qoldiq",
    "td2": "komissiya",
    "td3": "qabul qilmoq",
    "td4": "Umumiy raqam",
    "td5": "foydalanuvchi",
    "td6": "hissa qo'shmoq",
    "td7": "miqdori",
    "td8": "davlat",
    "g1": "Chekinmoq",
    "g2": "yuqoriga ko'taring",
    "g3": "Do'stlarni taklif qiling",
    "g4": "Hozir taklif qiling",
    "g5": "Do'stlaringiz tomonidan olingan har bir foyda uchun siz tegishli nisbatlar bo'yicha komissiyani olasiz",
    "g6": "Shaxsiy ma'lumot",
    "g7": "Kapital tafsilotlar",
    "g8": "Qayta to'ldirish",
    "g9": "Qaytarib olish yozuvi",
    "g10": "til",
    "g11": "Chiqish jurnaliga kirish",
    "g12": "Ishonasizmi va tizimga kirasizmi?",
    "yh1": "Foydalanuvchi haqida ma'lumot",
    "yh2": "Qayta ko'rib chiqmoq",
    "yh3": "sozlash; o'rnatish",
    "yh4": "Telefon",
    "yh6": "Asl parol",
    "yh7": "Yangi parol",
    "mx1": "Komissiya yig'ish",
    "mx2": "daromad",
    "mx3": "Chekinmoq",
    "mx4": "vaqt",
    "mx5": "Zaryadlamoq",
    "mx6": "Rad qilmoq",
    "mx7": "Davolash uchun",
    "mx8": "Eshitish manzil",
    "mx9": "Mavjud qoldiq",
    "mx10": "Aslida",
    "mx11": "Iltimos, pul mablag'ini kiriting",
    "cz2": "nusxa ko'chirmoq",
    "cz3": "Huquqiy valyutani tanlash",
    "cz4": "To'lamoq",
    "cz5": "Omonat manzili faqat TRC20-USDTni qo'llab-quvvatlaydi va minimal depozit miqdori 20USDT",
    "cz6": "Omonat manzili faqat ERC20-ni qo'llab-quvvatlaydi, minimal depozit miqdori 20uDDT",
    "cz1": "Omonat manzili faqat ERC20-ni qo'llab-quvvatlaydi, minimal depozit miqdori 20uDDT",
    "cz10": "Omonat manzili faqat BTCni qo'llab-quvvatlaydi",
    "qy1": "Mintaqaviy kod",
    "yq1": "Do'stlarni taklif qiling",
    "yq2": "Taklifnoma kodi",
    "yq3": "Do'stlaringizni katta daromad olish uchun taklif qiling",
    "yq4": "Qo'ysangchi; qani endi",
    "new1": "To'lov usuli"
  }
  